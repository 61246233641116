<template>
  <v-card class="px-4 pb-4 pt-5" width="400">
    <v-card-title>
      <v-img
        :src="logoImg"
        :height="this.environment !== 'ZenithAerotech' ? 65 : 150"
        contain
        alt="DroneShield logo with name"
      />
    </v-card-title>
    <v-layout row class="justify-center mb-1 mt-3 pt-3">
      <div class="text-center font-weight-bold subheading grey--text">
        DroneSentry-C2
      </div>
    </v-layout>
    <v-card-text>
      <v-form v-model="valid" @submit.prevent>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              ref="password"
              name="password"
              autocomplete="new-password"
              label="New Password"
              data-vv-name="password"
              data-vv-delay="300"
              :rules="[...rules.validPassword, validatePasswords('password')]"
              v-model="user.password"
              :append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :type="isPasswordVisible ? 'text' : 'password'"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              label="Password Confirmation"
              autocomplete="new-password"
              data-vv-name="password_confirmation"
              data-vv-delay="300"
              target="password"
              :rules="[
                rules.required,
                validatePasswords('password_confirmation')
              ]"
              v-model="user.password_confirmation"
              :append-icon="
                isConfirmationVisible ? 'visibility' : 'visibility_off'
              "
              @click:append="isConfirmationVisible = !isConfirmationVisible"
              :type="isConfirmationVisible ? 'text' : 'password'"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="mt-2">
          <v-flex xs12>
            <v-alert :value="showError" outline color="primary">
              {{ error }}
            </v-alert>
          </v-flex>
          <v-flex xs12 class="text-xs-center pt-4">
            <v-btn
              outline
              color="primary"
              @click="onClickChange"
              :disabled="!valid"
            >
              Change password
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { FormMixin } from '@/components/Mixins'

const props = {
  token: {
    type: String,
    default: ''
  }
}

export default {
  name: 'PasswordRecoveryCard',
  props,
  mixins: [FormMixin],
  data: () => ({
    showError: false,
    valid: false,
    user: {
      password: '',
      password_confirmation: ''
    },
    isPasswordVisible: false,
    isConfirmationVisible: false
  }),
  computed: {
    ...mapState('users', ['status', 'error']),
    ...mapGetters('system', ['assetsPath']),
    ...mapState('system', ['environment']),
    logoImg() {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    }
  },
  methods: {
    async onClickChange() {
      this.showError = false
      if (this.user.password !== this.user.password_confirmation)
        return this.$bus.$emit('APP_MESSAGE', {
          flush: 'manual',
          color: 'error',
          title: "Error Updating User's password",
          message: `Reason: password and password confirmation must match.`
        })
      let user = {
        token: this.token,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation
      }

      await this.$store.dispatch('users/RESET_USER_PASSWORD', user)
      if (this.status === 'OK') {
        this.$router.push('/login')
      } else {
        this.showError = true
      }
    },
    onClickClose() {
      this.$emit('clickClose')
    },
    clear() {
      this.$refs.form.reset()
    },
    validatePasswords(type) {
      if (this.user.password === this.user.password_confirmation) return true

      if (type === 'password') {
        return 'The password does not match the password confirmation'
      } else {
        return 'The password confirmation does not match the password'
      }
    }
  }
}
</script>
