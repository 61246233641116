<template>
  <v-card>
    <v-layout row wrap class="pa-0">
      <v-flex xs4>
        <v-img
          class="d-flex"
          :src="require('@/assets/brand.png')"
          height="58px"
          contain
        ></v-img>
      </v-flex>
      <v-flex></v-flex>
    </v-layout>
    <v-container class="mx-4">
      <!-- Enter the email address of the user to invite. -->
      <span class="title">Create your DroneShield Account</span>
    </v-container>
    <v-layout row>
      <v-flex xs8>
        <v-card-text class="px-5 pb-5">
          <v-layout row wrap align-center>
            <v-flex xs12 v-if="error">
              <v-alert :value="error" outline color="primary" dismissible>
                {{ error }}
              </v-alert>
            </v-flex>
            <v-flex xs12>
              <v-form v-model="valid">
                <v-text-field
                  data-vv-name="name-field"
                  color="primary"
                  name="name"
                  label="Name"
                  id="name"
                  v-model="user.name"
                  :rules="[rules.required]"
                />
                <v-text-field
                  color="primary"
                  name="email"
                  label="Email"
                  id="email"
                  type="email"
                  v-model="user.email"
                  readonly
                  disabled
                />
                <v-text-field
                  data-vv-name="username-field"
                  color="primary"
                  name="username"
                  label="Username"
                  id="username"
                  v-model="user.username"
                  :rules="[rules.required, rules.minName]"
                  required
                />
                <v-text-field
                  ref="password"
                  name="password"
                  label="Password"
                  data-vv-name="password"
                  data-vv-delay="300"
                  :rules="[
                    ...rules.validPassword,
                    validatePasswords('password')
                  ]"
                  v-model="user.password"
                  :append-icon="
                    isPasswordVisible ? 'visibility' : 'visibility_off'
                  "
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :type="isPasswordVisible ? 'text' : 'password'"
                />
                <v-text-field
                  label="Password Confirmation"
                  autocomplete="new-password"
                  data-vv-name="password_confirmation"
                  data-vv-delay="300"
                  target="password"
                  :rules="[
                    rules.required,
                    validatePasswords('password_confirmation')
                  ]"
                  v-model="user.password_confirmation"
                  :append-icon="
                    isConfirmationVisible ? 'visibility' : 'visibility_off'
                  "
                  @click:append="isConfirmationVisible = !isConfirmationVisible"
                  :type="isConfirmationVisible ? 'text' : 'password'"
                />
              </v-form>
            </v-flex>
          </v-layout>
          <v-layout row class="mt-4">
            <v-flex xs12 class="text-xs-center">
              <v-btn
                data-vv-name="signup-button"
                outline
                @click="onClickSave"
                color="primary"
                :disabled="!valid"
              >
                Sign Up
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-flex>
      <v-flex xs4>
        <v-img
          :src="require('@/assets/logo.png')"
          height="250px"
          contain
        ></v-img>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
const props = {
  status: {
    type: String
  },
  error: {
    type: String
  },
  userOptions: {
    type: Object
  }
}

import { FormMixin } from '@/components/Mixins'

export default {
  name: 'SignUserCard',
  props,
  mixins: [FormMixin],
  data: () => ({
    agreement: false,
    valid: false,
    user: {
      name: null,
      email: null,
      role: 'user',
      username: null,
      password: '',
      password_confirmation: ''
    },
    isCuurentVisible: false,
    isPasswordVisible: false,
    isConfirmationVisible: false
  }),
  methods: {
    onClickSave() {
      const { name, username, password, email, role } = this.user
      this.$emit('onSignUp', { name, username, password, email, role })
    },
    onClickClose() {
      this.$emit('clickClose')
    },
    validatePasswords(type) {
      if (this.user.password === this.user.password_confirmation) return true

      if (type === 'password') {
        return 'The password does not match the password confirmation'
      } else {
        return 'The password confirmation does not match the password'
      }
    }
  },
  watch: {
    userOptions() {
      Object.assign(this.user, this.userOptions)
    }
  }
}
</script>
